import React from "react";
import SmartInput, { ISmartInputProps } from './SmartInput';

export interface ISmartInputTextareaProps extends ISmartInputProps {
  rows?: number
}

export default function SmartInputTextarea(props: ISmartInputTextareaProps) {

  const {
    rows,
  } = props;
  return (
    <SmartInput
      {...props}
      render={({containerClassName, handleChange, inputProps, label, labelClassName}) => (
        <div className={containerClassName}>
          { (label) && <label className={labelClassName} htmlFor={ inputProps.id || inputProps.name }>{ label }</label> }
            <textarea
              {...inputProps}
              className={inputProps.className + " form-control"}
              onKeyDown={ (e) => { if (e.key === "Enter" && props.onEnter) props.onEnter() } }
              onChange={ handleChange }
              rows={rows ?? 3}
            ></textarea>
        </div>
      )}
    />
  );
}

