import classNames from 'classnames';
import React from "react";
import { consumeVisioBloc, IVisioBlocState, visioBloc } from '../../bloc/VisioBloc';
import Count from "./Count";
import RemoteVideos from './RemoteVideos';
import VideoCalling from './VideoCalling';

export interface IViseoProps {
}

class Viseo extends React.Component<IVisioBlocState, any> {

  // componentDidMount() {
  //   visioBloc.connect()
  // }

  // componentWillUnmount() {
  //   visioBloc.disconnect();
  // }

  render() {
    const { isMuted, cameraActive, peers, calling, rejected } = this.props;
    return (

      <div>
        {/* <div className="active-users-panel">
          <div className="row-flex">
            <Count/>
          </div>
          <div className="row-end">
            <i className="fal times" onClick={() => visioBloc.quit()} />
          </div>
        </div> */}
        <div className="video-container">
          {(calling || rejected) && <VideoCalling rejected={rejected}/>}
          {calling && <div className="end-0 p-4 position-absolute text-white top-0">
            <Count/>
          </div>}
          {!rejected && <div className="actions-video">
            <div className="buttons-video row-center">
              <div onClick={() => visioBloc.toggleCamera()} className="video-action-btn">
                <i className={classNames({"fas": true, [cameraActive ? "fa-video" : "fa-video-slash"]: true})}/>
              </div>
              <div onClick={() => visioBloc.toggleMute()} className="video-action-btn">
                <i className={classNames({"fas": true, [isMuted ? "fa-microphone-slash" : "fa-microphone"]: true})}/>
              </div>
              <div onClick={() => visioBloc.quit()} className="video-action-btn">
                <i className="fas fa-phone"/>
              </div>
            </div>
          </div>}
          <RemoteVideos localVideo={visioBloc.localVideo} peers={peers} />
        </div>
      </div>
    );
  }
};

export default consumeVisioBloc(Viseo);
