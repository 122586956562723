
import React from 'react';

export interface IDisplayBlocState {
  mode: DisplayMode
}

export interface IDisplayBlocConsumer extends IDisplayBlocState {
}

export const Context = React.createContext({})

export let displayBloc: DisplayBloc

export enum DisplayMode {
  standard,
  focusVisio
}

class DisplayBloc extends React.Component<any, IDisplayBlocState> {

  static styles = {
    [DisplayMode.standard]: {
      roomAreaWidth: "1",
      messagesAreaWidth: "2",
      visioAreaWidth: "1",
    },
    [DisplayMode.focusVisio]: {
      roomAreaWidth: "0 0 100px",
      messagesAreaWidth: "1",
      visioAreaWidth: "2"
    }
  }

  constructor(props: any) {
    super(props)
    displayBloc = this

    this.state = {
      mode: DisplayMode.standard
    }

  }

  toggleFocusVisio() {
    this.setState({mode: this.state.mode !== DisplayMode.focusVisio ? DisplayMode.focusVisio : DisplayMode.standard})
  }

  standard() {
    this.setState({mode: DisplayMode.standard})
  }

  focusVisio() {
    this.setState({mode: DisplayMode.focusVisio})
  }

  public render() {
    return (
      <Context.Provider value={this.state}>
        {this.props.children}
      </Context.Provider>
    )
  }
}

export default DisplayBloc;

export function consumeDisplayBloc(Component) {

  return class extends React.Component<any> {

    render() {
      return (
        <Context.Consumer>
          { (context) => (
            <Component {...this.props } {...context}/>
          )}
        </Context.Consumer>
      )
    }
  }
}
