import dayjs from 'dayjs';
import { sessionBloc } from '../bloc/SessionBloc';
import Attachment from './attachment';
import ArrayModelAttribute from './core/ArrayModelAttribute';
import Attribute from "./core/Attribute";
import AttributesCollection from "./core/AttributeCollection";
import ModelAttribute from './core/ModelAttribute';
import Model from "./Model";
import User from "./User";

export default class Message extends Model {

  public content: string;
  public userId: number;
  public user: User;
  public roomId: number;
  public hasError: boolean = false;
  public attachments: Attachment[];

  static modelUrl: string = "messages";
  public modelName: string = 'message';

  public attributes = new AttributesCollection({
    content: new Attribute("content"),
    user:    new ModelAttribute("user", User, {submitObject: false}),
    roomId:  new Attribute("roomId"),
    attachments: new ArrayModelAttribute("attachments", Attachment)
  });

  constructor(json) {
    super(json)
    this.fillAttributes(json);
  }

  fromMe() {
    return this.userId === sessionBloc.user?.id;
  }

  createdAtString() {
    let date = dayjs(this.createdAt);
    if (date.isSame(dayjs())) return date.format("D MMMM HH:mm")
    else return date.format("HH:mm")
  }

  isFile() {
    return this.attachments.length > 0;
  }

  getFileUrl() {
    return this.attachments[0].getFileUrl();
  }

}
