import React from 'react';
import Peer from "simple-peer";
import configByEnv from '../config/env';
import User from '../models/User';
import Visio from '../models/Visio';
import SocketEntityManager from '../services/SocketEntityManager';
import SocketService from '../services/SocketService';
import AdminAppBloc from './AdminAppBloc';
import AppBloc from './AppBloc';

export interface IVisioBlocState {
  isMuted: boolean
  cameraActive: boolean
  // active: boolean
  rejected: boolean
  calling: boolean
  visio: Visio
  userCalled: User
  peers: {[id: string]: Peer.Instance}
}

export enum VisioEvent {
  created = "visio:created",
  confirm = "visio:confirm",
  call_received = "visio:call_received",
  call_accepted = "visio:accepted",
  delete = "visio:delete",
  admindelete = "visio:admindelete",
  join = "visio:join",
  all_users = "visio:all_users",
  user_joined = "visio:user_joined",
  leave = "visio:leave",
  receiving_returned_signal = "visio:receiving_returned_signal",
  returning_signal = "visio:returning_signal",
  sending_signal = "visio:sending_signal",
  cancel = "visio:cancel",
  reject = "visio:reject"
}

export const Context = React.createContext({})

export let visioBloc: VisioBloc

class VisioBloc extends React.Component<any, IVisioBlocState> {

  public localVideo = React.createRef<HTMLVideoElement>();
  public screenStream: MediaStream;
  public hasSended: { [id: string]: boolean } = {};
  public screenShare = false;

  externalWindow
  audio

  constructor(props: any) {
    super(props);
    visioBloc = this;

    this.state = {
      isMuted: false,
      cameraActive: true,
      rejected: false,
      // active: false,
      calling: props.initiator,
      visio: null,
      userCalled: null,
      peers: {}
    };

  }

  async componentDidMount() {
    
    // SocketService.on(VisioEvent.created, this.onVisioCreated);
    if (AppBloc.get() instanceof AdminAppBloc) {
      SocketService.on(VisioEvent.cancel, this.onCallCanceled);
    }
    SocketService.on(VisioEvent.call_received, this.onCallReceived);
    SocketService.on(VisioEvent.call_accepted, this.onCallAccepted);
    SocketService.on(VisioEvent.delete, this.onVisioDeleted);
    SocketService.on(VisioEvent.admindelete, this.onVisioAdminDeleted);
    if (this.props.id) {
      let visio = await SocketEntityManager.show<Visio>(Visio, this.props.id);
      this.setState({visio}, () => this.connect())
    }
  }

  componentWillUnmount() {
    // SocketService.off(VisioEvent.created, this.onVisioCreated);
    SocketService.off(VisioEvent.call_received, this.onCallReceived);
    SocketService.off(VisioEvent.call_accepted, this.onCallAccepted);
    SocketService.off(VisioEvent.delete, this.onVisioDeleted);
    SocketService.off(VisioEvent.admindelete, this.onVisioAdminDeleted);
    SocketService.off(VisioEvent.cancel, this.onCallCanceled);
  }

  onCallAccepted = (data: any) => {
    this.setState({calling: false})
  }

  onVisioAdminDeleted = (data: any) => {
    AdminAppBloc.get().loadRooms()
    AdminAppBloc.get().room.visios = AdminAppBloc.get().room.visios.filter(v => v.id !== data.visioId);
  }

  onVisioDeleted = (data: any) => {
    window.close();
    // this.stopPeripheral();
    // this.hasSended = {};
    // this.setState({calledBy: null, calling: false, visio: null, active: false, peers: {}}, () => this.disconnect())
  }

  onCallReceived = (data: any) => {
    let visio = new Visio(data.visio);
    this.audio = new Audio("https://lasonotheque.org/UPLOAD/mp3/0452.mp3");
    this.audio.setAttribute("loop", "true");
    this.audio.play();
    AdminAppBloc.get().loadRooms()
    if (AdminAppBloc.get().room.id === visio.roomId) AdminAppBloc.get().room.visios.unshift(visio);
  }

  onCallCanceled = (data: any) => {
    this.audio?.pause();
    this.audio = null;
    AdminAppBloc.get().loadRooms()
    AdminAppBloc.get().room.visios = AdminAppBloc.get().room.visios.filter(v => v.id !== data.visioId);
  }

  onCallRejected = (data: any) => {
    this.setState({calling: false, rejected: true});
    this.stopPeripheral();
  }

  createVisio = async (visio) => {
    return await SocketEntityManager.create(visio);
  }

  joinVisio = async (visio: Visio) => {
    this.setState({visio})
  }

  getOtherSocket() {
    return Object.keys(this.state.peers)[0];
  }

  toggleMute() {
    this.screenStream.getAudioTracks()[0].enabled = !this.screenStream.getAudioTracks()[0].enabled;
    this.setState({isMuted: !this.state.isMuted});
  }

  toggleCamera() {
    this.screenStream.getVideoTracks()[0].enabled = !this.screenStream.getVideoTracks()[0].enabled;
    this.setState({cameraActive: !this.state.cameraActive});
  }

  stopPeripheral = () => {
    this.screenStream?.getTracks().forEach(t => t.stop());
    this.screenStream = null;
  }

  closeWindow = () => {
    this.cancelCall(this.state.visio);
    // if (this.state.calling) this.cancelCall();
    // else if (this.state.calledBy) this.rejectCall();
    // else this.quit();
  }

  quit() {
    SocketService.get().socket.emit(VisioEvent.leave, {visioId: this.state.visio.id});
    window.close();
    // this.stopPeripheral();
    
    // this.hasSended = {};
    // this.setState({visio: null, active: false, peers: {}}, () => {
    //   this.disconnect();
    //   displayBloc?.standard();
    // });
  }

  acceptCall = async (visio) => {
    this.audio?.pause();
    this.audio = null;
    SocketService.get().socket.emit(VisioEvent.call_accepted, {socketId: this.getOtherSocket(), visioId: visio.id});
    this.externalWindow = window.open(`${configByEnv.protocol + configByEnv.frontUrl}/room/${visio.id}?roomId=${visio.roomId}`, 'Appeler un commercial', 'width=800,height=600,left=300,top=300');
    AdminAppBloc.get().state.rooms.find(r => r.id === visio.roomId).visios[0].status = "running";
    AdminAppBloc.get().room.visios[0].status = "running"
    AdminAppBloc.get().setState({})
    // this.setState({calledBy: null});
  }

  cancelCall(visio) {
    this.stopPeripheral();
    SocketService.get().socket.emit(VisioEvent.cancel, {
      visioId: visio.id,
    });
    window.close();
    // this.setState({calling: false, active: false});
  }

  rejectCall(visio) {
    this.audio?.pause();
    this.audio = null;
    SocketService.get().socket.emit(VisioEvent.reject, {
      visioId: visio.id,
    });
    AdminAppBloc.get().loadRooms()
    AdminAppBloc.get().room.visios = AdminAppBloc.get().room.visios.filter(v => v.id !== visio.id);
  }

  //DISPLAY
  joinUser = ({signal, socketId}: {signal: Peer.SignalData, socketId: string}) => {
    
    const stream = this.screenStream;
    const peer = this.addPeer(signal, socketId, stream);

    this.setState({peers: {...this.state.peers, [socketId]: peer}});
  }

  receiveSignal = ({signal, socketId}: {signal: Peer.SignalData, socketId: string}) => {
    const peer = this.getPeer(socketId);
    if (peer) peer.signal(signal);
  }

  getPeer(socketId) {
    return this.state.peers[socketId];
  }

  leaveUser = async (data: any) => {
    this.getPeer(data.socketId).destroy();
    delete this.hasSended[data.socketId];
    const { [data.socketId]: _, ...rest } = this.state.peers;
    let peers = rest
    this.setState({peers});
  }

  createAllUsers = (socketIds: string[]) => {
    
    socketIds.forEach(socketId => {
      const peer = this.createPeer(socketId, SocketService.get().socket.id, this.screenStream);
      this.setState({peers: {...this.state.peers, [socketId]: peer}});
    })
  }

  createPeer = (remoteSocketId: string, socketId: string, stream: MediaStream): Peer.Instance => {
    
    const peer = new Peer({
      initiator: true,
      trickle: false,
      stream,
    });

    peer.on("signal", signal => {
      
      if (!this.hasSended[remoteSocketId]) {
        SocketService.get().socket.emit(VisioEvent.sending_signal, { remoteSocketId, socketId, signal })
        this.hasSended[remoteSocketId] = true;
      }
    })

    return peer;
  }

  addPeer = (incomingSignal: Peer.SignalData, socketId: string, stream: MediaStream) => {
    const peer = new Peer({
      initiator: false,
      trickle: false,
      stream,
    })

    peer.on("signal", signal => {
      SocketService.get().socket.emit(VisioEvent.returning_signal, { signal, socketId })
    })

    peer.signal(incomingSignal);
    return peer;

  }

  toggleScreenShare = () => {
    if (!this.screenShare) {
      let mediaDevices: any = window.navigator.mediaDevices;
      mediaDevices.getDisplayMedia({ video: true, audio: true }).then(stream => {
        this.screenStream = stream;
        Object.keys(this.state.peers).forEach(key => {
          this.getPeer(key).removeStream(this.screenStream);
          this.getPeer(key).addStream(stream);
        })
      });
    } else {
      Object.keys(this.state.peers).forEach(key => {
        this.getPeer(key).removeStream(this.screenStream);
        this.getPeer(key).addStream(this.screenStream);
      })
    }
    this.screenShare = !this.screenShare;
  }

  // connectInitiator() {
  //   navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then(stream => {
  //     this.localVideo.current.srcObject = stream;
  //     this.screenStream = stream;
  //     SocketService.on(VisioEvent.all_users, this.createAllUsers)
  //     SocketService.on(VisioEvent.user_joined, this.joinUser);
  //     SocketService.on(VisioEvent.receiving_returned_signal, this.receiveSignal);
  //     SocketService.on(VisioEvent.leave, this.leaveUser);
  //     SocketService.get().socket.emit(VisioEvent.join, {visioId: this.state.visio.id});
  //   })
  // }

  connect() {
    navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then(stream => {
      this.localVideo.current.srcObject = stream;
      this.screenStream = stream;
      SocketService.on(VisioEvent.all_users, this.createAllUsers)
      SocketService.on(VisioEvent.user_joined, this.joinUser);
      SocketService.on(VisioEvent.leave, this.leaveUser);
      SocketService.on(VisioEvent.receiving_returned_signal, this.receiveSignal);
      SocketService.get().socket.emit(VisioEvent.join, {visioId: this.state.visio.id});
    })
  }

  disconnect() {
    SocketService.off(VisioEvent.all_users, this.createAllUsers);
    SocketService.off(VisioEvent.user_joined, this.joinUser);
    SocketService.off(VisioEvent.leave, this.leaveUser);
    SocketService.off(VisioEvent.receiving_returned_signal, this.receiveSignal);
  }

  public render() {
    return (
      <Context.Provider value={this.state}>
        {this.props.children}
      </Context.Provider>
    )
  }
}

export default VisioBloc;

export function consumeVisioBloc(Component) {

  return class extends React.Component<any> {
    
    render() {
      return (
        <Context.Consumer>
          { (context) => (
            <Component {...this.props } {...context}/>
          )}
        </Context.Consumer>
      )
    }
  }
}

