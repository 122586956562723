import classNames from 'classnames';
import * as React from 'react';
import AdminAppBloc from '../../bloc/AdminAppBloc';
import Room from '../../models/Room';
import AvatarLetter from '../common/AvatarLetter';

export interface IRoomItemProps {
  room: Room
  active: boolean
}

export default function RoomItem ({room, active}: IRoomItemProps) {

  function render2() {
    if (room.visios.length > 0) return <div className="ms-auto row-flex">
      <div className="bg-primary height-5 me-3 rounded-circle row-center text-white width-5">
        <i className='fas fa-video'></i>
      </div>
      <div className="text-sm text-primary fw-bold">{room.visios[0].status === "running" ? "En cours" : "Demande"}</div>
    </div>
    const amount = room.unreadAmount();
    return amount > 0 ?
      <div className="ms-auto row-flex">
        <div className="bg-danger height-5 me-3 rounded-circle row-center text-l text-white width-5">{amount}</div>
        <div className="text-sm text-danger fw-bold">{room.lastMessage()?.createdAtString()}</div>
      </div>
      :
      <div className="ms-auto">
        <div className="text-sm text-secondary">{room.lastMessage()?.createdAtString()}</div>
      </div>
  }
  return (
    <div onClick={() => AdminAppBloc.get().selectRoom(room)} className={classNames({"room row-flex": true, "active": active})} key={room.id}>
      <div className="me-3">
        <AvatarLetter name={room.name} />
      </div>
      <div className="text-truncate">
        <div>{room.name}</div>
        <div className="text-secondary text-truncate me-3">{room.lastMessage()?.content}</div>
      </div>
      {render2()}
    </div>
  );
}
