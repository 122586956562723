import { AxiosResponse } from 'axios';
import Model from '../../models/Model';
import EntityManager from '../EntityManager';

export default class ModelResponse<T extends Model> {

  public model: T;
  public ok = false;
  public errors
  public response: AxiosResponse;

  constructor(response: AxiosResponse, modelClass) {
    this.response = response;
    if (response.status === 200) {
      this.ok = true;
      if (response.data.model) this.model = EntityManager.toModel(modelClass, response.data.model);
    } else {
      this.errors = response.data.errors;
    }
  }

}