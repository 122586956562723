import * as React from 'react';
import AppBloc from '../bloc/AppBloc';
import ClientAppBase from '../components/client/ClientAppBase';
import SmartColorInput from '../components/input/SmartColorInput';
import SmartInputText from '../components/input/SmartInputText';
import SmartInputTextarea from '../components/input/SmartInputTextarea';
import SmartSwitch from '../components/input/SmartSwitch';
import SettingsService, { SettingKey } from '../services/SettingsService';

export interface ISettingPageProps {
}

export default class SettingPage extends React.Component<ISettingPageProps> {

  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentDidMount() {
    SettingsService.loadSettings().then(val => this.setState({}));
  }

  render() {
    if (!SettingsService.loaded) return <></>
    return (
      <div className="row w-100">
        <div className="px-6 col-5">
          <h1 className="mb-5">Paramètre du widget</h1>
          <SmartSwitch id={SettingKey.online} model={SettingsService.get(SettingKey.online)} name="value" containerClassName="mb-3" label={SettingsService.getLabel(SettingKey.online)} autoSave onChange={() => AppBloc.get().setState({})} />
          <SmartSwitch id={SettingKey.visio_online} model={SettingsService.get(SettingKey.visio_online)} name="value" containerClassName="mb-3" label={SettingsService.getLabel(SettingKey.visio_online)} autoSave onChange={() => AppBloc.get().setState({})} />
          <div className="mb-3">
            <div className="form-label">Couleur du widget</div>
            <SmartColorInput containerClassName="me-3" model={SettingsService.get(SettingKey.color1)} name="value" autoSave onChange={() => AppBloc.get().setState({})}/>
            <SmartColorInput model={SettingsService.get(SettingKey.color2)} name="value" autoSave onChange={() => AppBloc.get().setState({})}/>
          </div>
          <SmartInputText model={SettingsService.get(SettingKey.home_message)} name="value" containerClassName="mb-3" label={SettingsService.getLabel(SettingKey.home_message)} autoSave onChange={() => AppBloc.get().setState({})} />
          <SmartInputText model={SettingsService.get(SettingKey.title)} name="value" containerClassName="mb-3" label={SettingsService.getLabel(SettingKey.title)} autoSave onChange={() => AppBloc.get().setState({})} />
          <SmartInputText model={SettingsService.get(SettingKey.sub_title)} name="value" containerClassName="mb-3" label={SettingsService.getLabel(SettingKey.sub_title)} autoSave onChange={() => AppBloc.get().setState({})}/>
          <SmartInputTextarea model={SettingsService.get(SettingKey.offline_message)} name="value" rows={6} containerClassName="mb-3" label={SettingsService.getLabel(SettingKey.offline_message)} autoSave onChange={() => AppBloc.get().setState({})} />
          <button onClick={() => {}} className="btn btn-primary">Mettre à jour</button>
        </div>
        <div className="px-6 col-5 position-relative">
          <div className="client-app-area position-absolute">
            <ClientAppBase/>
          </div>
        </div>
      </div>
    );
  }
}

// root.style.position = "fixed";
// root.style.display = "flex";
// root.style.border = "none";
// root.style.right = "1rem";
// root.style.bottom = "1rem";
