import * as React from 'react';
import AdminAppBloc, { IAdminAppBlocState } from '../../bloc/AdminAppBloc';
import { consumeAppBloc } from '../../bloc/AppBloc';
import RoomItem from './RoomItem';

export interface IRoomAreaBodyProps {
}

function RoomAreaBody ({rooms, activeRoom}: IRoomAreaBodyProps & IAdminAppBlocState) {
  if (!rooms) return <></>
  // const sortedRooms = rooms.sort((a, b) => a.lastMessage()?.createdAt < b.lastMessage()?.createdAt ? 1 : -1 )
  const newRooms = [];
  const recentRooms = [];
  rooms.forEach((room) => {
    if (room.userId) recentRooms.push(room)
    else newRooms.push(room)
  })
  
  return (
    <div className="p-3">
      {newRooms.length > 0 && <div className="mb-3">
        <div className="text-secondary">Nouvelles conversations</div>
        {newRooms.map((room) => (
          <div onClick={() => AdminAppBloc.get().assignRoomToMe(room)}>
            <RoomItem key={room.getKey()} room={room} active={activeRoom?.id === room.id} />
          </div>
        ))}
      </div>}
      <div className="text-secondary">Dernières conversations</div>
      {recentRooms.filter((r) => r.messageCount > 0 || r.visios.length > 0 ).sort((a, b) => (
        b.messages.length > 0 ? b.messages[0].createdAt : b.createdAt) - (
          a.messages.length > 0 ? a.messages[0].createdAt : a.createdAt)).map((room) => (
        <RoomItem key={room.getKey()} room={room} active={activeRoom?.id === room.id} />
      ))}
    </div>
  );
}

export default consumeAppBloc(RoomAreaBody)