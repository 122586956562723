import classNames from 'classnames';
import * as React from 'react';
import AppBloc from '../../bloc/AppBloc';
import { sessionBloc } from '../../bloc/SessionBloc';
import Message from '../../models/Message';
import AvatarLetter from '../common/AvatarLetter';
import AttachmentPresent from './AttachmentPresent';

export interface IMessageComponentProps {
  className: string;
  message: Message;
  withAvatar?: boolean
  style?: React.CSSProperties
}

export interface IMessageComponentState {
}

export default class MessageComponent extends React.Component<IMessageComponentProps, any> {
  constructor(props: IMessageComponentProps) {
    super(props);

    this.state = {
      deletable: false
    }
  }


  public render() {
    const { className, message, withAvatar, style } = this.props;
    return (
      <>
        <div onMouseEnter={() => this.setState({deletable: true})} onMouseLeave={() => this.setState({deletable: false})} className={className}>
          {withAvatar && <AvatarLetter name={AppBloc.get().room.name} />}
          {message.isFile() ? <div className="bg-white shadow rounded-2 ms-3 mt-3 w-75">
            <AttachmentPresent attachment={message.attachments[0]} />
          </div>
          :
          <div className={classNames("message ms-3")}  style={style}>
            <span className={"message-content"} style={{whiteSpace: "pre-line"}} >{ message.content }</span>
          </div>}
          {(sessionBloc && message.userId === sessionBloc.user.id && this.state.deletable) && 
          <div onClick={() => AppBloc.get().deleteMessage(message)} className='size-5 row-center text-secondary pointer'>
            <i className='fas fa-times'></i>
          </div>
          }
        </div>
        </>
    );
  }
}
