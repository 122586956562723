import Message from '../models/Message';
import Room from '../models/Room';
import Visio from '../models/Visio';
import SocketEntityManager from '../services/SocketEntityManager';
import SocketService from '../services/SocketService';
import AppBloc, { IAppBlocState, IRemoteTyping } from './AppBloc';
import { appBloc } from './BlocProvider';
import { sessionBloc } from './SessionBloc';

export interface IAdminAppBlocState extends IAppBlocState {
  rooms: Room[]
}

class AdminAppBloc extends AppBloc<IAdminAppBlocState> {

  public inputs = {}

  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      rooms: null,
    }
  }

  get input() {
    if (!this.state.activeRoom) return "";
    return this.inputs[this.state.activeRoom.id]
  }

  set input(val) {
    if (!this.state.activeRoom) return ;
    this.inputs[this.state.activeRoom.id] = val
  }

  get readAtName() { return "adminReadAt"; }

  onRemoteUnTyping(data: IRemoteTyping) {
    this.setState({typingMessage: data.typingMessage, isTyping: this.state.isTyping.filter(id => data.roomId !== id)})
  }

  async componentDidMount() {
    super.componentDidMount();
    SocketService.on("room:created", (data) => {
      this.loadRooms();
    });
  }

  async loadRunningVisio(roomId) {
    let visios = await SocketEntityManager.all<Visio>(Visio, {params: {status: "pending", roomId}});
    
    return visios.length > 0 ? visios[0] : null;
  }

  emitAuth() {
    SocketService.get().socket.emit("auth", {
      userId: sessionBloc.user.id,
      roomIds: this.state.rooms.map(r => r.id)
    })
  }

  static get(): AdminAppBloc {
    return appBloc;
  }

  async deleteActiveRoom() {
    let res = window.confirm("Etes vous sur de vouloir supprimer cette conversation ?")
    if (res) {
      await SocketEntityManager.delete<Room>(this.room);
      this.setState({rooms: this.state.rooms.filter((room) => room.id !== this.room.id), activeRoom: null});
    }
  }

  async sendMessage() {
    let message = await this.buildMessage({
      content: this.input,
      roomId: this.room.id,
      userId: sessionBloc.user.id
    });
    this.onNewMessage(message);
    this.initInputState();
  }

  initInputState() {
    this.input = ""
    super.initInputState();
  }

  inputChange(content: string) {
    this.inputs[this.room.id] = content;
    super.inputChange(content);
  }

  onNewMessage(message: Message) {
    console.log(message);
    
    let rooms = [...this.state.rooms];
    rooms.find(r => r.id === message.roomId).messages.unshift(message);
    this.setState({rooms});
  }

  async loadRoomsAndShowLast() {
    let rooms = await SocketEntityManager.all<Room>(Room);
    console.log(rooms);
    let activeRoom = rooms.length > 0 ? rooms[0] : null;
    rooms.forEach((room) => {
      this.inputs[room.id] = ""
    })
    this.setState({rooms, activeRoom, input: this.input}, () => this.emitAuth())
  }

  async loadRooms() {
    let rooms = await SocketEntityManager.all<Room>(Room);

  // useEffect(() => {
  //   if (!activeRoom) return;
  //   AdminAppBloc.get().loadRunningVisio(activeRoom.id).then((visio: Visio) => {
  //     if (visio) visioBloc.setState({visio, calledBy: activeRoom})
  //     else visioBloc.setState({visio: null, calledBy: null})
  //   });
  // }, [activeRoom]);
    this.setState({rooms}, () => this.emitAuth())
  }

  async selectRoom(activeRoom: Room) {
    if (!activeRoom.isRead()) await activeRoom.read();
    this.setState({activeRoom, input: this.inputs[activeRoom.id]}, () => this.focus());
  }

  public assignRoomToMe(room) {
    room.userId = sessionBloc.user.id;
    SocketEntityManager.update(room);
  }

}

export default AdminAppBloc;

