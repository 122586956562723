import SocketEntityManager from './SocketEntityManager';
import Setting from '../models/Setting';

export enum SettingKey {
  title = "title",
  sub_title = "sub_title",
  home_message = "home_message",
  online = "online",
  visio_online = "visio_online",
  offline_message = "offline_message",
  color1 = "color1",
  color2 = "color2"
}

class SettingsService {

  public settings: {[key: string]: Setting} = {}
  public loaded = false;

  get(key: string): Setting {
    return this.settings[key];
  }

  getValue(key: string) {
    return this.get(key)?.value;
  }

  getBooleanValue(key: string) {
    return this.getValue(key) === "true";
  }

  getLabel(key: string) {
    return this.get(key).label;
  }

  async loadSettings() {
    let settings = await SocketEntityManager.all<Setting>(Setting);
    settings.forEach(s => {
      this.settings[s.key] = s;
    })
    this.loaded = true;
  }

  gradientColor() {
    return {background: `linear-gradient(45deg, ${this.getValue(SettingKey.color1)}, ${this.getValue(SettingKey.color2)})`}
  }

}
export default new SettingsService()