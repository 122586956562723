import * as React from 'react';

export interface IDotsJumpingProps {
}

export default function DotsJumping (props: IDotsJumpingProps) {
  return (
    <div className="typing">
      <div className="typing__dot"></div>
      <div className="typing__dot"></div>
      <div className="typing__dot"></div>
    </div>
  );
}
