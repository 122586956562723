import React, { Component } from 'react'
import Viseo from '../components/visio/Viseo'

interface Props {
}
interface State {
  
}

export default class RoomPage extends Component<Props, State> {
  state = {}

  render() {
    return (
      <div>
        <Viseo/>
      </div>
    )
  }
}
