import * as React from 'react';

let stream: MediaStream;

export class Video extends React.Component<any> {

  public ref = React.createRef<HTMLVideoElement>();

  componentDidMount() {
    if (stream) return this.ref.current.srcObject = stream;
    this.props.peer.on("stream", this.setStream);
  }

  setStream = newStream => {
    stream = newStream;
    this.ref.current.srcObject = newStream;
  };

  render() {
    return (
      <video style={ this.props.style } playsInline autoPlay ref={ this.ref } />
    );
  }
}
