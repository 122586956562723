
import io, { Socket } from 'socket.io-client';
import env from '../config/env';


let socketService: SocketService;

export default class SocketService {

  public socket: Socket;

  constructor() {
    this.socket = io(env.protocol + env.apiUrl, { transports : ['websocket'] })

    this.socket.on('connect', () => {
      console.log("connexion ok")
    });

  }

  static get() {
    if (socketService) return socketService;
    SocketService.initSocket();
    return socketService;
  }

  static on(eventType, callBack) {
    SocketService.get().socket.on(eventType, callBack);
  }

  static off(eventType, callBack) {
    SocketService.get().socket.off(eventType, callBack);
  }

  static close() {
    SocketService.get().socket.close();
  }

  static open() {
    SocketService.get().socket.open();
  }

  static initSocket() {
    if (!socketService) socketService = new SocketService();
    socketService.socket.open();
  }

}

