import * as React from 'react';
import AdminAppBloc from '../../bloc/AdminAppBloc';
import { consumeDisplayBloc } from '../../bloc/DisplayBloc';
import ChatInput from './ChatInput';
import MessagesList from './MessagesList';

export interface IMessagesAreaProps {
}

class MessagesArea extends React.Component<IMessagesAreaProps> {
  public render() {
    return (
      <div className="messages-area">
        <div className="area-header row-between">
          <h3 className="mb-0">Chat</h3>
          <div onClick={() => AdminAppBloc.get().deleteActiveRoom()} className='btn-icon text-secondary'>
            <i className='fas fa-trash'></i>
          </div>
        </div>
        <MessagesList isFromMe={(message) => message.userId} />
        <div className="bottom-chat-container">
          <ChatInput/>
        </div>
      </div>
    );
  }
}

export default consumeDisplayBloc(MessagesArea);