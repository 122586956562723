import * as React from 'react';
import Attachment from '../../models/attachment';

export interface IAttachmentPresentProps {
  attachment: Attachment
}

export default function AttachmentPresent ({attachment}: IAttachmentPresentProps) {

  function content() {
    if (attachment.mediaType?.startsWith("image")) return <img className='img-fluid' src={attachment.getFileUrl()} alt=''/>
    if (attachment.mediaType?.endsWith("pdf")) return <div className='col-center p-3'>
      <i className='fas fa-file-pdf color-pdf' style={{fontSize: "5rem"}}></i>
      <div>{attachment.url}</div>
    </div>
    // if (attachment.mediaType.startsWith("audio")) return <></>
    // if (attachment.mediaType.startsWith("video")) return <></>
    return <div className='p-3'>{attachment.url}</div>
  }

  return <a href={attachment.getFileUrl()} target="_blank" rel="noreferrer">
    {content()}
  </a>
}
