import React, { Component } from 'react';
import { consumeAppBloc } from '../../bloc/AppBloc';
import { IClientAppBlocState } from '../../bloc/ClientAppBloc';
import SettingsService, { SettingKey } from '../../services/SettingsService';
import classNames from 'classnames';

interface Props {
  onReduce
  askVisio
  
}
interface State {
  
}

class ClientHeader extends Component<IClientAppBlocState & Props, State> {
  render() {
    const {onReduce, visioOnline, askVisio} = this.props;
    return (
      <div className="client-app-header" style={SettingsService.gradientColor()}>
        <div>
          <h4 className="mb-0">{SettingsService.getValue(SettingKey.title)}</h4>
          <div className="mb-0">{SettingsService.getValue(SettingKey.sub_title)}</div>
        </div>
        <div className="row-end">
          <div className={classNames({"btn-icon me-2 tooltip-container": true, "btn-icon-disabled": !visioOnline})} onClick={visioOnline ? askVisio : null}>
            <i className="fas fa-video"></i>
            <div className="tooltip text-center">
              {visioOnline ? "Appeler un commercial" : "Aucun commercial n'est disponible pour le moment..."}
            </div>
          </div>
          <div className="btn-icon" onClick={onReduce}>
            <i className="fas fa-chevron-down"></i>
          </div>
        </div>
      </div>
    )
  }
}

export default consumeAppBloc(ClientHeader)