import { Component, createRef, Fragment, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import AdminRouter from './AdminRouter';
import './assets/sass/app.scss';
import AdminAppBloc from './bloc/AdminAppBloc';
import { consumeSessionBloc } from './bloc/SessionBloc';
import VisioBloc from './bloc/VisioBloc';
import PrivateRoute from './components/common/PrivateRoute';
import Navbar from './components/layout/Navbar';
import LoginPage from './pages/LoginPage';
import LogoutPage from './pages/LogoutPage';
import AuthService from './services/AuthService';
import SettingsService from './services/SettingsService';
import DisplayBloc from './bloc/DisplayBloc';
import RoomPage from './pages/RoomPage';
import queryString from 'query-string';
import ClientAppBloc from './bloc/ClientAppBloc';
export let refNavbar = createRef<any>()

class App extends Component<any, any> {

  constructor(props) {
    super(props);

    this.state = {
      init: false
    }
  }

  async componentDidMount() {
    if (AuthService.hasToken()) await AuthService.me();
    await SettingsService.loadSettings();
    this.setState({init: true});
  }

  render() {
    if (!this.state.init) return <></>
    return (
      <Switch>
        <Route path="/login" component={LoginPage} />
        <Route path="/logout" component={LogoutPage} />
        <Route path="/room/:id" component={(p) => <ClientAppBloc roomId={queryString.parse(p.location.search).roomId} ><VisioBloc initiator={queryString.parse(p.location.search).initiator} id={p.match.params.id}><RoomPage /></VisioBloc></ClientAppBloc>} />
        {!this.props.logged && <Redirect to="/login" />}
        <Route path="/" component={() => (
          <AdminAppBloc>
            <DisplayBloc>
              <VisioBloc>
                <div className="d-flex">
                  <Navbar/>
                  <div className="border-start rounded home" style={{width: "calc(100vw - 100px)"}}>
                    {AdminRouter.map(route => (
                      <Fragment key={route.url}>
                        <PrivateRoute exact={route.exact} path={route.url} component={() => {
                          return <RouteWrapper title={route.label}>
                            <route.page/>
                          </RouteWrapper>
                        }} />
                      </Fragment>
                    ))}
                  </div>
                </div>
              </VisioBloc>
            </DisplayBloc>
          </AdminAppBloc>
        )} />
        {/* <div id="visio-app"></div> */}
      </Switch>
    );
  }
}

export default consumeSessionBloc(App);

function RouteWrapper(props: any) {

  useEffect(() => {
    // refNavbar.current.setState({title: props.title})
  })

  return props.children;
}