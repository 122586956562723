import * as React from 'react';
import AppBloc, { consumeAppBloc, IAppBlocState } from '../../bloc/AppBloc';
import SettingsService from '../../services/SettingsService';
import MediaFileButton from '../common/MediaFileButton';

export interface IChatInputProps {
  onFocus
  onBlur
}

class ChatInput extends React.Component<IChatInputProps & IAppBlocState, any> {

  async onSend() {
    const {input} = this.props;
    if (!input) return;
    AppBloc.get().sendMessage();
  }

  onChange = (e) => {
    if (e.currentTarget.value[e.currentTarget.value.length-1] === "\n") {
      return this.onSend();
    } else {
      return AppBloc.get().inputChange(e.target.value);
    }
  }

  render() {
    const {input, onFocus, onBlur} = this.props;
    // if (!AppBloc.get().room) return <></>;
    return (
      <div className="bottom-chat">
        <textarea onFocus={onFocus} onBlur={onBlur} rows={1} ref={AppBloc.get().inputRef} value={input} autoFocus onChange={this.onChange} placeholder="Ecrivez votre message ..." className="form-control pe-11" />
        <div className="actions-btn">
          <MediaFileButton className='me-3' onPreviewReady={ () => console.log("preview") } onFileChange={ (file) => {
            AppBloc.get().sendFile(file);
          }}>
            <div className="btn-icon">
              <i className="fas fa-paperclip"></i>
            </div>
          </MediaFileButton>
          <div onClick={() => this.onSend()} className="send-button" style={SettingsService.gradientColor()}>
            <i className="fas fa-paper-plane"></i>
          </div>
        </div>
      </div>
    );
  }
}

export default consumeAppBloc(ChatInput)