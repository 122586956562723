import * as React from 'react';
import SmartInput, { ISmartInputProps } from './SmartInput';
import classNames from 'classnames';
import uniqueId from 'lodash/uniqueId';


export default function SmartColorInput(props: ISmartInputProps) {

  let id = uniqueId();
  return (
    <SmartInput
      {...props}
      render={({containerClassName, handleChange, inputProps}) => (
        <label id={id} className={classNames({"color-indice position-relative mb-0 selected": true, [containerClassName]: !!containerClassName})}>
          <div className={classNames({"color-indice-input": true, "border": inputProps.value === "#ffffff", [inputProps.className]: !!inputProps.className})} style={{backgroundColor: inputProps.value}}></div>
          <input {...inputProps} className="visually-hidden position-absolute" name="color" onChange={handleChange} type="color" id={id}/>
        </label>
      )}
    />
  );
}
