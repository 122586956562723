import * as React from 'react';
import AdminAppBloc from '../../bloc/AdminAppBloc';
import { consumeDisplayBloc } from '../../bloc/DisplayBloc';
import RoomAreaBody from './RoomAreaBody';

export interface IRoomsAreaProps {
}

class RoomsArea extends React.Component<IRoomsAreaProps> {

  componentDidMount() {
    AdminAppBloc.get().loadRoomsAndShowLast();
  }

  public render() {
    return (
      <div className="rooms-area overflow-auto">
        <div className="area-header">
          <div className="bg-light rounded width-7 row-center height-7 me-3">
            <i className="fas fa-comments text-secondary"></i>
          </div>
          <h3 className="mb-0">Conversations</h3>
        </div>
        <RoomAreaBody/>
      </div>
    );
  }
}

export default consumeDisplayBloc(RoomsArea);