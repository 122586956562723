import Model from './Model';
import Attribute from './core/Attribute';
import AttributesCollection from './core/AttributeCollection';
import FileAttribute from './core/FileAttribute';
import ModelAttribute from './core/ModelAttribute';
import Message from './Message';
import configByEnv from '../config/env';

export default class Attachment extends Model {

  public url: string;
  public filename: string;
  public mediaType: string;
  public messageId: number;
  public file: File;

  static modelUrl = "images";
  public modelName = "image";

  public attributes = new AttributesCollection({
    url: new Attribute("url", {submittable: false}),
    mediaType: new Attribute("mediaType"),
    filename: new Attribute("filename"),
    message: new ModelAttribute("message", Message),
    file: new FileAttribute("file"),
  });

  constructor(json) {
    super(json)
    this.fillAttributes(json);
  }

  // static of(parentModel: Model, json) {
  //   return new ImageModel({imageableType: parentModel.getBackendModelType(), imageableId: parentModel.id, ...json});
  // }

  loadPreview = (file: File): Promise<string | ArrayBuffer> => {
    return new Promise((resolve, reject) => {
      let reader: FileReader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      }
      reader.readAsDataURL(file)
    })
  }

  getFileUrl() {
    return configByEnv.protocol + configByEnv.apiUrl + "media/" + this.url;
  }

}