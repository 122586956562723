import * as React from 'react';
import { consumeDisplayBloc, displayBloc } from '../../bloc/DisplayBloc';
import VisioAreaBody from './VisioAreaBody';

export interface IVisioAreaProps {
}

function VisioArea(props) {
  return (
    <div className="visio-area">
      <div className="area-header">
        <div className="bg-light rounded width-7 height-7 row-center me-3 pointer" onClick={() => displayBloc.toggleFocusVisio()}>
          <i className="fas fa-user text-secondary"></i>
        </div>
        <h3 className="mb-0">Contact</h3>
      </div>
      <VisioAreaBody/>
    </div>
  );
}

export default consumeDisplayBloc(VisioArea);