import classNames from 'classnames';
import * as React from 'react';

export enum AvatarLetterSize {
  xs = "2.5rem",
  sm = 45,
  md = 80,
  lg = 150,
}

export interface IAvatarLetterProps {
  name: string
  className?: string
}

export default class AvatarLetter extends React.Component<IAvatarLetterProps, any> {

  public colors = ['#cb4c93', '#cb4c4c', '#4ccbb9', '#4ecb4c', '#cb7b4c', '#cbaf4c', '#5f4ccb', '#4c96cb'];

  getStyle(): React.CSSProperties {
    let size = AvatarLetterSize.xs
    let style: React.CSSProperties = {
      width: size,
      minWidth: size,
      height: size,
      fontSize: "1.25rem",
      borderRadius: "50%",
    }
    style.backgroundColor = this.props.name;
    return style;
  }

  getLetter = (name: string) => {
    return name[1];
  }

  render() {
    const { name, className } = this.props;
    if (!name) return <></>
    return (
      <div title={name} style={this.getStyle()} className={classNames({[className]: !!className, "avatar-letter": true})}>
        { this.getLetter(name) }
      </div>
    );
  }
}
