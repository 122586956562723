import Home from './pages/Home';
import LogoutPage from './pages/LogoutPage';
import SettingPage from './pages/SettingPage';


const routes = [
  {
    icon: "fa-comments",
    label: "Accueil",
    url: "/",
    page: Home,
    exact: true
  },{
    icon: "fa-cogs",
    label: "Paramètres",
    url: "/settings",
    page: SettingPage,
    exact: true
  },{
  //   icon: "fa-user",
  //   label: "Chat",
  //   url: "/profile",
  //   page: ProfilePage,
  //   exact: true
  // },{
    icon: "fa-power-off",
    label: "Chat",
    url: "/logout",
    page: LogoutPage,
    exact: true
  }
]

export default routes;