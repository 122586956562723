import * as React from 'react';
import { visioBloc, VisioEvent } from '../../bloc/VisioBloc';
import SocketService from '../../services/SocketService';
import SmallLoading from '../common/SmallLoading';

export interface IVideoCallingProps {
  rejected
}

export interface IVideoCallingState {
}

export default class VideoCalling extends React.Component<IVideoCallingProps, IVideoCallingState> {

  componentDidMount() {
    SocketService.on(VisioEvent.reject, visioBloc.onCallRejected);
  }

  componentWillUnmount() {
    SocketService.off(VisioEvent.reject, visioBloc.onCallRejected);
  }

  public render() {
    return this.props.rejected ?
      <div className="m-auto">
        <div className="col-center mb-6">
          <div className="text-white text-2">Tous nos commerciaux sont actuellement occupé.</div>
          <div className="text-white text-2 mb-3">Veuillez réessayer plus tard.</div>
        </div>
        <button onClick={() => window.close()} className="btn btn-danger w-100">Fermer</button>
      </div>
      :
      <div className="m-auto">
        <div className="col-center mb-6">
          <div className="text-white fw-bold text-2 mb-3">Appel en cours...</div>
          <SmallLoading/>
        </div>
        <button onClick={() => visioBloc.cancelCall(visioBloc.state.visio)} className="btn btn-danger w-100">Annuler</button>
      </div>
  }
}
