import classNames from 'classnames';
import * as React from 'react';
import ClientAppBloc from '../../bloc/ClientAppBloc';
import { consumeVisioBloc, IVisioBlocState, visioBloc, VisioEvent } from '../../bloc/VisioBloc';
import configByEnv from '../../config/env';
import Visio from '../../models/Visio';
import SettingsService, { SettingKey } from '../../services/SettingsService';
import ChatInput from '../messaging/ChatInput';
import MessagesList from '../messaging/MessagesList';
import ClientHeader from './ClientHeader';
import queryString from 'query-string';
import SocketService from '../../services/SocketService';

export interface IClientAppProps {
  onReduce: any
}

class ClientApp extends React.Component<IClientAppProps & IVisioBlocState, any> {

  currentVisio;
  parentWidth;
  parentHeight;

  constructor(props) {
    super(props);
    let param = queryString.parse(window.location.search)
    this.parentWidth = param.parentWidth;
    this.parentHeight = param.parentHeight;
    this.state = {
      showConfirmation: false,
      style: this.getStyle(false),
    }
  }

  getStyle(open) {
    let viewHeight = this.parentHeight
    if (open) viewHeight /= 2;
    let height
    if (viewHeight < 560) height = viewHeight;
    height += "px";
    
    return {height}
  }

  onKeyboardShow() {
    this.setState({style: this.getStyle(true)})
  }

  onKeyboardHide() {
    this.setState({style: this.getStyle(false)})
  }


  askVisio = async () => {
    this.setState({showConfirmation: true})
    let bloc = ClientAppBloc.get();
    let room = bloc.room
    if (!room) {
      room = await bloc.buildNewRoom();
      bloc.saveRoomInStorage(room);
    }
    let visio = await visioBloc.createVisio(new Visio({roomId: room.id}));
    this.currentVisio = visio
    bloc.setState({activeRoom: room});
  }

  runVisio = async () => {
    window.open(`${configByEnv.protocol + configByEnv.frontUrl}/room/${this.currentVisio.id}?initiator=true&roomId=${this.currentVisio.roomId}`, '_blank', 'width=800,height=600,left=300,top=300,scrollbars=yes,status=yes,location=yes')
    SocketService.get().socket.emit(VisioEvent.confirm, {visioId: this.currentVisio.id});
    this.setState({showConfirmation: false});
  }

  public render() {
    const {onReduce} = this.props;
    const {showConfirmation} = this.state;
    
    return (
      <div style={this.state.style} className={classNames({"client-app": true})}  >
        <div className="messages-area position-relative messages-area-client w-100">
          { showConfirmation &&
            <div className="confirmation-visio ">
              <div className="fw-bold mb-4 px-6 text-2 text-center text-primary">
                Lancer une visio avec un commercial ?
              </div>
              <div className="row-between px-6">
                <button onClick={() => this.setState({showConfirmation: false})} className="btn btn-light w-100 mx-2">Annuler</button>
                <button onClick={() => this.runVisio()} className="btn btn-primary w-100 mx-2">
                  
                  <div>C'est parti</div>
                </button>
              </div>
            </div>
          }
          <ClientHeader onReduce={onReduce} askVisio={this.askVisio} />
          {SettingsService.getValue(SettingKey.online) ?
            <>
              <MessagesList isFromMe={(message) => !message.userId}/>
              <div className="bottom-chat-container p-0">
                <ChatInput onFocus={() => this.onKeyboardShow()} onBlur={() => this.onKeyboardHide()}/>
              </div>
            </>
            :
            <div className="mx-auto p-6 text-center">
              {SettingsService.getValue(SettingKey.offline_message)}
            </div>
          }
        </div>
      </div>
    );
  }
}

export default consumeVisioBloc(ClientApp)