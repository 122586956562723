import * as React from 'react';
import { consumeAppBloc } from '../../bloc/AppBloc';
import SettingsService, { SettingKey } from '../../services/SettingsService';
import ClientApp from './ClientApp';

export interface IClientAppBaseProps {
}

class ClientAppBase extends React.Component<IClientAppBaseProps, any> {

  constructor(props) {
    super(props);
    this.state = {
      open: false
    }
  }

  open() {
    this.setState({open: true})
  }

  close() {
    this.setState({open: false})
  }

  render() {
    if (!SettingsService.loaded) return <></>
    return this.state.open ? <ClientApp onReduce={() => this.close()} /> : (
      <>
        {SettingsService.getValue(SettingKey.online) && <div className="floating-message">
          {SettingsService.getValue(SettingKey.home_message)}
        </div>}
        <div className="client-app-button" style={SettingsService.gradientColor()} onClick={() => this.open()}>
          <i className="fas fa-comments"></i>
        </div>
      </>
    );

  }
}

export default consumeAppBloc(ClientAppBase)