import dayjs from 'dayjs';
import * as React from 'react';
import { IAdminAppBlocState } from '../../bloc/AdminAppBloc';
import { consumeAppBloc } from '../../bloc/AppBloc';
import TextIcon from '../common/TextIcon';
import VideoArea from './VideoArea';

export interface IVisioAreaBodyProps {
}

function VisioAreaBody ({activeRoom}: IAdminAppBlocState) {

  if (!activeRoom) return <></>
  return (
    <>
      <div className="p-3">
        <div className="text-secondary mb-2">Informations</div>
        <div className="mb-2">{dayjs(activeRoom.createdAt).format("D MMMM YYYY hh:mm")}</div>
        <TextIcon leftIcon="fas fa-server" className="mb-2">{activeRoom.ip}</TextIcon>
        <TextIcon leftIcon="fas fa-laptop" className="mb-2">{activeRoom.device}</TextIcon>
        {/* <TextIcon leftIcon="fas fa-envelope">{activeRoom.email}</TextIcon>
        <TextIcon leftIcon="fas fa-phone">{activeRoom.phone}</TextIcon> */}
      </div>
      <div className="p-3">
        <VideoArea/>
      </div>
    </>
  );
}

export default consumeAppBloc(VisioAreaBody)