
import React from 'react';
import User from '../models/User';
import authService from '../services/AuthService';

export interface ISessionBlocState {
  user: User | undefined
  logged: boolean
}

export interface ISessionBlocConsumer extends ISessionBlocState {

}

export const Context = React.createContext({})

export let sessionBloc: SessionBloc

class SessionBloc extends React.Component<any, ISessionBlocState> {

  constructor(props: any) {
    super(props)
    sessionBloc = this

    this.state = {
      user: null,
      logged: false,
    }

  }

  get user() { return this.state.user }

  public getUserId = () => this.state.user?.id;

  public async setSessionData(user: User) {
    return new Promise((resolve) => {
      this.setState({user, logged: true}, () => resolve(true));
    })
  }

  public setUser(user: User) {
    this.setState({user});
  }

  logged() {
    return !!this.state.user;
  }

  logout() {
    authService.logout();
    this.setState({logged: false, user: null});
  }

  public render() {
    return (
      <Context.Provider value={this.state}>
        {this.props.children}
      </Context.Provider>
    )
  }
}

export default SessionBloc;

export function consumeSessionBloc(Component) {

  return class extends React.Component<any> {

    render() {
      return (
        <Context.Consumer>
          { (context) => (
            <Component {...this.props } {...context}/>
          )}
        </Context.Consumer>
      )
    }
  }
}
