import * as React from 'react';
import { consumeAppBloc, IAppBlocState } from '../../bloc/AppBloc';
import { IVisioBlocState } from '../../bloc/VisioBloc';
import VideoCallView from '../visio/VideoCallView';

function VideoArea ({activeRoom}: IVisioBlocState & IAppBlocState) {
  return (
    <div>
      {activeRoom.visios.length > 0 && <VideoCallView visio={activeRoom.visios[0]} />}
      {/* {active && <Viseo/>} */}
    </div>
  );
}

export default consumeAppBloc(VideoArea)