import Attribute from './core/Attribute';
import AttributesCollection from './core/AttributeCollection';
import Model from './Model';

export default class Visio extends Model {

  public roomId: number;
  public status: string;
  static modelUrl = "visios";

  public attributes = new AttributesCollection({
    roomId: new Attribute("roomId"),
    status:  new Attribute("status"),
  });

  constructor(json) {
    super(json)
    this.fillAttributes(json);
  }
}