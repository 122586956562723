import * as React from 'react';

export interface ICountProps {
}

export interface ICountState {
  count: number
}

export default class Count extends React.Component<ICountProps, ICountState> {

  public interval;

  constructor(props: ICountProps) {
    super(props);

    this.state = {
      count: 0
    }
  }

  componentDidMount() {
    this.startCount();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  startCount() {
    this.interval = setInterval(() => this.setState({count: this.state.count + 1}), 1000)
  }

  display (seconds) {
    const format = val => `0${Math.floor(val)}`.slice(-2)
    // const hours = seconds / 3600
    const minutes = (seconds % 3600) / 60
    return [minutes, seconds % 60].map(format).join(':')
  }

  public render() {
    return (
      <div className="count">
        {this.display(this.state.count)}
      </div>
    );
  }
}
