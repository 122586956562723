import * as React from 'react';
import SmallLoading from '../common/SmallLoading';

export interface IButtonProps extends React.ButtonHTMLAttributes<HTMLDivElement>{
  loadingColor?: string
  manual?: any
  as?
}

export default class Button extends React.Component<IButtonProps, {loading: boolean}> {

  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
  }

  onClick = (e) => {
    if (!this.props.manual) this.loadingState();
    this.props.onClick && this.props.onClick(e);
  }

  loadingState() {
    this.setState({loading: true});
  }

  defaultState() {
    this.setState({loading: false});
  }

  render() {
    const {loadingColor, onClick, as, ...other} = this.props;
    let HtmlElement = as || "div"
    return (
      <HtmlElement onClick={this.onClick} {...other} >{this.state.loading ? <SmallLoading className={loadingColor} /> : other.children}</HtmlElement>
    );
  }
}
