import Attribute from "./core/Attribute";
import AttributesCollection from "./core/AttributeCollection";
import Model from "./Model";

export default class Setting extends Model {

  public label: string;
  public key: string;
  public value: string;
  public type: number;

  static modelUrl: string = "settings";
  public modelName: string = 'setting';

  public attributes = new AttributesCollection({
    label: new Attribute("label"),
    key:  new Attribute("key"),
    value:  new Attribute("value"),
    type:  new Attribute("type"),
  });

  constructor(json) {
    super(json)
    this.fillAttributes(json);
  }

}
