import * as React from 'react';
import AppBloc from '../../bloc/AppBloc';

export interface IMoreMessageButtonProps {
}

export default function MoreMessageButton (props: IMoreMessageButtonProps) {

  return (
    <div className="link ms-auto mb-3 text-center text-s" onClick={() => AppBloc.get().loadMore()}>
      Charger toute la conversation
    </div>
  );
}
