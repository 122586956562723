import classNames from 'classnames';
import * as React from 'react';
import Button from '../components/common/Button';
import SmartInputText from '../components/input/SmartInputText';
import LoginResponse from '../services/apiResponse/LoginResponse';
import authService from '../services/AuthService';
import { Redirect } from 'react-router';

export interface ILoginPageProps {
}

export interface ILoginPageState {
  [arg: string]: any
  email: string
  password: string
  isChecking: boolean
  redirect: boolean
  errors: {[name: string]: string}
}

export default class LoginPage extends React.Component<ILoginPageProps, ILoginPageState> {

  public button = React.createRef<Button>()

  constructor(props: ILoginPageProps) {
    super(props);

    this.state = {
      email: "",
      password: "",
      isChecking: false,
      redirect: false,
      errors: null
    }
  }

  onKeyPressed = (e) => {
    if (e.key === "Enter") this.submit(e)
  }

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value, errors: null})
  }

  onKeyPress = (e) => {
    if (e.key === "Enter") this.submit(e)
  }

  onLogin = async (response: LoginResponse) => {
    if (response.ok) {
      this.setState({redirect: true})
    } else {
      this.setState({errors: response.errors, isChecking: false});
    }
  }

  submit = async (e) => {
    e.preventDefault();
    this.button.current.loadingState();
    let response: LoginResponse = await authService.login({email: this.state.email, password: this.state.password})
    if (!response.ok) this.button.current.defaultState();
    this.onLogin(response)
  }

  public render() {
    const {email, password, errors, redirect} = this.state;
    if (redirect) return <Redirect to="/" />
    return (
      <div className="d-flex min-vh-100">
        <div className="m-auto p-8 rounded-2 shadow" style={{width: "600px"}}>
          <h3 className="fw-bold mb-3">
          Connectez-vous à votre compte
          </h3>
          <form className="pt-2" onSubmit={this.submit}>
            <SmartInputText type="email" containerClassName="mb-3" className={classNames({"is-invalid": !!errors})} label="Adresse Email" value={email} name="email" required onChange={this.handleChange} autoFocus/>
            <SmartInputText id="password" type="password" className={classNames({"is-invalid": !!errors})} label="Mot de passe" name="password" value={password} required onChange={this.handleChange}/>
            {errors && <div className="text-danger mt-1">{errors.credential}</div>}
            <div className="text-end link mt-3 mb-5">
              <div>Mot de passe oublié ?</div>
            </div>
            <div className="text-center">
              <Button type="submit" manual={1} ref={this.button} as={"button"} className="btn btn-primary m-auto w-100 text-dark-primary">
                SE CONNECTER
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
