const env = process.env.NODE_ENV

const forceProd = false;

export interface EnvConfig {
    protocol: string
    frontUrl: string
    apiUrl: string
    nodeUrl: string
    clientUrl: string
}

const envConfig = {
  development: {
    protocol: 'http://',
    frontUrl: 'localhost:3000',
    clientUrl: 'localhost:3001',
    apiUrl: 'localhost:3501/',
  },
  production: {
    protocol: 'https://',
    frontUrl: 'demovisio.urioz.com',
    clientUrl: 'clientvisio.urioz.com',
    apiUrl: 'apivisio.urioz.com/',
  }
}

// const shopifyKey = "97b51b59ecde31f29ff0c638cd8afc3b"
// const shopifyPwd = "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"
// const passwd = "Compo46'-"
// const urlExample = "https://97b51b59ecde31f29ff0c638cd8afc3b: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@anthonylibet.myshopify.com/admin/api/2021-04/orders.json"
// const shareCode = "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"

export const getFrontUrl = () => envConfig[forceProd ? "production" : env].protocol + envConfig[forceProd ? "production" : env].frontUrl;

let configByEnv: EnvConfig = envConfig[forceProd ? "production" : env]

export default configByEnv
