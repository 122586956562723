import * as React from 'react';
import AppBloc from '../../bloc/AppBloc';
import Room from '../../models/Room';
import AvatarLetter from '../common/AvatarLetter';
import DotsJumping from '../common/DotsJumping';

export interface ITypingMessageProps {
  typingMessage: string
  isTyping: number[]
  activeRoom: Room
  withAvatar?: boolean
}

export default function TypingMessage ({typingMessage, isTyping, withAvatar, activeRoom}: ITypingMessageProps) {
  let display = isTyping.includes(activeRoom.id)
  return (display || typingMessage) && <div className="mt-3">
      <div className="text-secondary text-s">En train d'écrire ...</div>
      <div className="remote-message">
        {withAvatar && <AvatarLetter name={AppBloc.get().room.name} />}
        <div className="message row-center" style={{background: "#ffffff80"}}>
          <span className={display ? "me-2" : ""}>{typingMessage}</span>
          {display && <DotsJumping/>}
        </div>
      </div>
    </div>
}
