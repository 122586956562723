import { AxiosResponse } from "axios";
import Model from '../models/Model'
import apiService from "./ApiService";
import ModelResponse from './apiResponse/ModelResponse';
import ModelCollectionResponse from './apiResponse/ModelCollectionResponse';
import PaginateResponse from './apiResponse/PaginateResponse';
import SocketService from './SocketService';
import EntityManager from "./EntityManager";

export default class SocketEntityManager {

  static async all<T extends Model>(modelClass: any, options: SocketEntityManagerOption = {}) {
    let fullpath = SocketEntityManager.getPath(modelClass, options.path);
    let params = SocketEntityManager.buildParam(options.params || {}, options);
    return new Promise<T[]>((resolve) => {
      SocketService.get().socket.emit(fullpath + ":all", params, (response) => {
        return resolve(
        EntityManager.toModels(modelClass, response.models)
      )})
    })
  }

  static async allWithPaginate<T extends Model>(modelClass: any, options: SocketEntityManagerOption = {}) {
    let fullpath = SocketEntityManager.getPath(modelClass, options.path);
    let params = SocketEntityManager.buildParam(options.params, options);
    let response: AxiosResponse = await apiService.get(fullpath, {...params, paginate: true});
    return new PaginateResponse<T>(response, modelClass);
  }

  static async show<T extends Model>(modelClass: any, id, options: SocketEntityManagerOption = {}) {
    let fullpath = SocketEntityManager.getPath(modelClass, options.path);
    let params = SocketEntityManager.buildParam(options.params, options);
    return new Promise<T>((resolve, reject) => {
      SocketService.get().socket.emit(fullpath + ":show", {...params, id}, (response) => {
        if (response.code && response.code > 299) reject(response);
        resolve(
          EntityManager.toModel(modelClass, response.model)
        )
      })
    })
  }

  static async create<T extends Model>(model: T, options: CreateOptions = {}) {
    let fullpath = SocketEntityManager.getPath(model.constructor);
    let param = SocketEntityManager.buildParam(model.getSocketParam(options.only), options);
    console.log(param);
    
    return new Promise<T>((resolve) => {
      SocketService.get().socket.emit(fullpath + ":create", {model: param}, (response) => resolve(
        EntityManager.toModel(model.constructor, response.model)
      ))
    })
  }

  static async update<T extends Model>(model: T, options: UpdateOptions = {}) {
    let param = SocketEntityManager.buildParam(model.getApiParam(options.only), options);
    let id;
    if (param instanceof FormData) {
      param.append('_method', 'PUT');
      id = param.get('id')
    } else {
      id = param.id;
      param['_method'] = 'PUT'
    }
    let fullpath = SocketEntityManager.getPath(model.constructor);
    return new Promise<T>((resolve) => {
      SocketService.get().socket.emit(fullpath + ":update", {model: {...param, id}}, (response) => resolve(
        EntityManager.toModel(model.constructor, response.model)
      ))
    })
  }

  static async delete<T extends Model>(model: T, options: DeleteOptions = {}): Promise<any> {
    let fullpath = SocketEntityManager.getPath(model.constructor);
    return new Promise<T>((resolve) => {
      SocketService.get().socket.emit(fullpath + ":delete", {model}, (response) => resolve(
        null
      ))
    })
  }

  static async deleteMany<T extends Model>(modelClass, options: DeleteOptions = {}): Promise<any> {
    let fullpath = SocketEntityManager.getPath(modelClass, "all");
    let response: AxiosResponse = await apiService.delete(fullpath, {modelIds: options.params.map(m => m.id)})
    return new ModelResponse<T>(response, modelClass);
  }

  static createMany<T extends Model>(modelClass, data: any[]): Promise<any> {
    return SocketEntityManager.post<T>(modelClass, {params: {models: data.map(d => d.getApiParam())}, path: "all"});
  }

  static async updateMany<T extends Model>(modelClass, options: UpdateOptions = {}): Promise<any> {
    let param = {models: options.params};
    param.models = param.models.map((m) => m.getApiParam(options.only))
    param = SocketEntityManager.buildParam(param, options);
    if (param instanceof FormData) {
      param.append('_method', 'PUT');
    } else {
      param['_method'] = 'PUT'
    }
    let fullpath = SocketEntityManager.getPath(modelClass, "all");
    let response: AxiosResponse = await apiService.post(fullpath, param, {multipart: options.multipart})
    return new ModelResponse<T>(response, modelClass);
  }

  static async get<T extends Model>(modelClass: any, options: SocketEntityManagerOption = {}) {
    let response: AxiosResponse;
    try {
      let fullpath = SocketEntityManager.getPath(modelClass, options.path);
      response = await apiService.get(fullpath, options.params)
    } catch (error) {
      response = error.response;
    }
    return new ModelResponse<T>(response, modelClass);
  }

  static async getCollection<T extends Model>(modelClass: any, options: SocketEntityManagerOption = {}) {
    let response: AxiosResponse;
    try {
      let fullpath = SocketEntityManager.getPath(modelClass, options.path);
      response = await apiService.get(fullpath, options.params)
    } catch (error) {
      response = error.response;
    }
    return new ModelCollectionResponse<T>(response, modelClass);
  }

  static async post<T extends Model>(modelClass: any, options: SocketEntityManagerOption = {}) {
    let response: AxiosResponse;
    try {
      let fullpath = SocketEntityManager.getPath(modelClass, options.path);
      response = await apiService.post(fullpath, options.params)
    } catch (error) {
      response = error.response;
    }
    return new ModelResponse<T>(response, modelClass);
  }

  static async postCollection<T extends Model>(modelClass: any, options: SocketEntityManagerOption = {}) {
    let response: AxiosResponse;
    try {
      let fullpath = SocketEntityManager.getPath(modelClass, options.path);
      response = await apiService.post(fullpath, options.params)
    } catch (error) {
      response = error.response;
    }
    return new ModelCollectionResponse<T>(response, modelClass);
  }

  static buildParam(params, options: SocketEntityManagerOption) {
    if (options.merge) params = Object.assign(params, options.merge);
    if (options.parentModel) params[options.parentModel.modelName + "Id"] = options.parentModel.id;
    if (options.with) params = Object.assign(params, {_with: options.with});
    return params;
  }

  static toCamelCase(json) {
    return json
  }

  static toModel(modelClass, json) {
    return new modelClass(SocketEntityManager.toCamelCase(json));
  }

  static toModels(modelClass, array: any[]) {
    return array.map(json => new modelClass(SocketEntityManager.toCamelCase(json)));
  }

  static getPath(modelClass: any, path?: string): string {
    let fullpath = modelClass.modelUrl
    if (path) {
      fullpath += `:${path}`
    }
    return fullpath;
  }

  static displayError(response: AxiosResponse) {
  }

}

export interface SocketEntityManagerOption {
  parentModel?: Model
  params?: any
  direct?: boolean
  withHeader?: boolean
  path?: string
  toaster?: boolean
  with?: string[]
  only?: string[]
  merge?: Object
  multipart?: boolean
}

export interface CreateOptions extends SocketEntityManagerOption {
}

export interface UpdateOptions extends SocketEntityManagerOption {
}

export interface DeleteOptions extends SocketEntityManagerOption {
  confirmMessage?: string
}
