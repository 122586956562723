
import UAParser from 'ua-parser-js';
import Message from '../models/Message';
import Room from '../models/Room';
import SettingsService, { SettingKey } from '../services/SettingsService';
import SocketEntityManager from '../services/SocketEntityManager';
import SocketService from '../services/SocketService';
import AppBloc, { IAppBlocState, IRemoteTyping } from './AppBloc';
import { appBloc } from './BlocProvider';

export interface IClientAppBlocState extends IAppBlocState {
  visioOnline: boolean
}

const ROOMIDCACHE = "visio:roomId"

class ClientAppBloc extends AppBloc<IClientAppBlocState> {

  constructor(props) {
    super(props);
    let visioOnline: boolean = SettingsService.getBooleanValue(SettingKey.visio_online) && SettingsService.getBooleanValue(SettingKey.online)
    this.state = {
      ...this.state,
      visioOnline,
    }
  }

  async emitAuth() {
    let roomId = localStorage.getItem(ROOMIDCACHE) || this.props.roomId;

    if (roomId) {
      try {
        let activeRoom = await this.loadRoom(roomId);
        this.setState({activeRoom});
        SocketService.get().socket.emit("auth", {roomIds: [parseInt(roomId)]});
        
      } catch (error) {
        localStorage.removeItem(ROOMIDCACHE);
        SocketService.get().socket.emit("auth", {roomIds: []});
      }
    }
  }

  get readAtName() { return "clientReadAt"; }

  onRemoteUnTyping(data: IRemoteTyping) {
    this.setState({isTyping: this.state.isTyping.filter(id => data.roomId !== id)})
  }

  onNewMessage(message: Message) {
    this.room.messages.unshift(message);
    this.setState({});
  }

  async loadRoom(roomId): Promise<Room> {
    return SocketEntityManager.show<Room>(Room, roomId);
  }

  async componentDidMount() {
    super.componentDidMount();
    SocketService.on("visio:connected", this.visioOnlineChange);
    SocketService.get().socket.emit("visio:connected");
    this.emitAuth();
  }

  componentWillUnmount() {
    SocketService.off("visio:connected", this.visioOnlineChange);
  }

  visioOnlineChange = (data) => {
    this.setState({visioOnline: data.connected > 0})
  }

  static get(): ClientAppBloc {
    return appBloc;
  }

  async buildNewRoom() {
    var parser = new UAParser();
    let userAgent = parser.getResult()
    return await SocketEntityManager.create(new Room({device: userAgent.browser.name + " on " + userAgent.os.name}));
  }

  async sendMessage() {
    let roomId = this.room?.id;
    if (!this.room) {
      const newRoom = await this.buildNewRoom();
      roomId = newRoom.id;
      this.saveRoomInStorage(newRoom);
      this.setState({activeRoom: newRoom});
    }
    let message = await this.buildMessage({
      content: this.state.input,
      roomId
    });
    this.onNewMessage(message);
    this.initInputState();
  }
  
  saveRoomInStorage(room) {
    localStorage.setItem(ROOMIDCACHE, room.id);
  }

  async read() {
    this.room.clientReadAt = new Date();
    await SocketEntityManager.update(this.room);
  }

}

export default ClientAppBloc;
