
import debounce from 'lodash/debounce';
import React from 'react';
import Message from '../models/Message';
import Room from '../models/Room';
import SocketEntityManager from '../services/SocketEntityManager';
import SocketService from '../services/SocketService';
import { appBloc, setAppBloc } from './BlocProvider';
import Attachment from '../models/attachment';
import { sessionBloc } from './SessionBloc';

export interface IAppBlocState {
  activeRoom: Room
  input: string
  typingMessage: string
  isTyping: number[]
}

export interface IRemoteTyping {
  roomId: number
  typingMessage?: string
}

export const Context = React.createContext({})

class AppBloc<S extends IAppBlocState> extends React.Component<any, S> {

  inputRef = React.createRef<HTMLTextAreaElement>();

  public isTyping = false;

  static get(): AppBloc<IAppBlocState> {
    return appBloc;
  }

  constructor(props: any) {
    super(props)
    setAppBloc(this)

    this.state = {
      activeRoom: null,
      input: "",
      isTyping: [],
      typingMessage: null
    } as S

  }

  buildMessage(data) {
    return SocketEntityManager.create(new Message(data))
  }

  emitAuth(callback) {}

  get room() { return this.state.activeRoom }
  get readAtName() { return ""; }

  async componentDidMount() {
    SocketService.initSocket();
    SocketService.on("messages:created", (data) => {
      const message = new Message(data.model);
      if (this.inCurrentRoom(message)) this.room.read();
      this.onNewMessage(message);
    });
    SocketService.on("messages:typing", (data) => {
      this.onRemoteTyping(data.roomId)
    });
    SocketService.on("messages:untyping", (data) => {
      this.onRemoteUnTyping(data)
    });
  }

  onRemoteTyping(roomId) {
    this.setState({isTyping: [...this.state.isTyping, roomId]})
  }

  onRemoteUnTyping(data: IRemoteTyping) {}

  onNewMessage(message: Message) {}

  inCurrentRoom(message: Message) {
    return this.room?.id === message.roomId;
  }

  deleteMessage(message: Message) {
    SocketEntityManager.delete<Message>(message);
    this.room.messages = this.room.messages.filter((m) => m.id !== message.id);
    this.setState({})
  }

  async loadMore() {
    let messages = await SocketEntityManager.all<Message>(Message, {parentModel: this.room});
    this.room.messages = messages;
    this.setState({})
  }

  inputChange(content: string) {
    console.log(content);
    
    this.setState({input: content});
    this.type();
  }

  async loadMessageOfRoom(room: Room) {
    return await SocketEntityManager.all<Message>(Message, {parentModel: room})
  }

  focus() {
    this.inputRef.current.focus();
  }

  async sendMessage() {}

  async sendFile(file: File) {
    console.log(file);
    
    let message = await this.buildMessage({attachments: [new Attachment({file, filename: file.name})], roomId: this.room.id, userId: sessionBloc?.user?.id});
    console.log("after build");
    this.onNewMessage(message);
    console.log("after refresh");
    this.initInputState();
  }

  initInputState() {
    this.setState({input: ""}, () => {
      this.focus();
      this.emitUntyping();
    });
  }

  public emitTyping() {
    SocketService.get().socket.emit("messages:typing", {
      "roomId": this.room.id
    });
  }

  public debouncedTyping = debounce(() => {
    this.isTyping = false;
    this.emitUntyping()
  }, 2000);

  public emitUntyping = () => {
    if (this.isTyping) {
      this.debouncedTyping.cancel();
      this.isTyping = false;
    }
    SocketService.get().socket.emit("messages:untyping", {
      "roomId": this.room.id,
      "typingMessage": this.state.input
    })
  };

  public type() {
    if (!this.room) return;
    if (!this.isTyping) {
      this.isTyping = true;
      this.emitTyping();
    }
    this.debouncedTyping();
  }

  public render() {
    return (
      <Context.Provider value={this.state}>
        {this.props.children}
      </Context.Provider>
    )
  }
}

export default AppBloc;

export function consumeAppBloc(Component) {

  return class extends React.Component<any> {

    render() {
      return (
        <Context.Consumer>
          { (context) => (
            <Component {...this.props } {...context}/>
          )}
        </Context.Consumer>
      )
    }
  }
}