import * as React from 'react';

export interface IMediaFileButtonProps {
  onFileChange: (arg: File) => void;
  onPreviewReady?: (result: string | ArrayBuffer, file: File) => void
  className?: string;
  id?: any
  children: any
}

export default function MediaFileButton({onFileChange, onPreviewReady = null, className, id, children}: IMediaFileButtonProps) {

  let input = React.useRef<HTMLInputElement>()

  const handleChange = (e) => {
    if (e.target.files.length === 0) return;
    // 8MO max
    console.log(e.target.files[0].size);
    
    const maxAllowedSize = 6 * 1024 * 1024;
    if(e.target.files[0].size > maxAllowedSize){
      window.alert("La taille du fichier est trop importante");
      return;
    }
    let file: File = e.target.files[0];
    onFileChange(file);
    if (!!onPreviewReady) loadPreview(file);
  }

  const loadPreview = (file: File) => {
    let reader: FileReader = new FileReader();
    reader.onloadend = () => {
      onPreviewReady(reader.result, file);
    }
    reader.readAsDataURL(file)
  }

  return (
    <div onClick={() => input.current.click()} className={className || ""}>
      <input ref={input} onChange={handleChange} type="file" name="file" id={id || "post-file"} className="media-file"/>
      {/* <div className="m-0" style={{width: "40px", height: "40px"}}> */}
        {children}
      {/* </div> */}
    </div>
  );
}
