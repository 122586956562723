import dayjs from 'dayjs';
import * as React from 'react';
import { consumeAppBloc, IAppBlocState } from '../../bloc/AppBloc';
import Message from '../../models/Message';
import SettingsService from '../../services/SettingsService';
import ChatDateLabel from './ChatDateLabel';
import MessageComponent from './MessageComponent';
import MoreMessageButton from './MoreMessageButton';
import TypingMessage from './TypingMessage';

export interface IMessagesListProps {
  isFromMe: (message: Message) => boolean
}

export interface IMessagesListState {
}

class MessagesList extends React.Component<IMessagesListProps & IAppBlocState, IMessagesListState> {

  listContainer = React.createRef<HTMLDivElement>()

  componentDidMount() {
    this.listContainer.current?.lastElementChild?.scrollIntoView();
  }

  componentDidUpdate() {
    this.listContainer.current?.lastElementChild?.scrollIntoView();
  }

  buildContent = () => {
    let components: JSX.Element[] = [];
    let previousUserId;
    let dateRef: Date;
    if (this.props.activeRoom.hasMoreMessages()) {
      components.push(<MoreMessageButton/>);
    }
    [...this.props.activeRoom.messages].reverse().forEach((message: Message, index: number) => {
      if (dateRef == null || dayjs(message.createdAt).diff(dayjs(dateRef)) > 60000) {
        components.push(<ChatDateLabel key={"ChatDateLabel" + index} date={message.createdAt}/>);
      }
      dateRef = message.createdAt;

      if (!this.props.isFromMe(message)) {
        if (message.user?.id !== previousUserId) {
          components.push(<div>{message.user?.getFullName() || "anonyme"}</div>);
        }
        components.push(<MessageComponent withAvatar={!message.userId} key={"msg" + index} message={message} className={"remote-message"} />);
      } else {
        components.push(<MessageComponent key={"msg" + index} message={message} className={"own-message"} style={SettingsService.gradientColor()} />);
      }
    });
    return components.map((c) => c)
  }

  public render() {
    const {activeRoom, typingMessage, isTyping} = this.props
    return (
      <div ref={this.listContainer} className="messages-list">
        {activeRoom && this.buildContent()}
        {activeRoom && <TypingMessage typingMessage={typingMessage} activeRoom={activeRoom}  isTyping={isTyping} />}
      </div>
    );
  }
}

export default consumeAppBloc(MessagesList)