import * as React from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

export interface IChatDateLabelProps {
  date: Date
}

export default function ChatDateLabel (props: IChatDateLabelProps) {
  return (
    <div className="chat-date-label mt-2">
      {dayjs(props.date).fromNow()}
    </div>
  );
}
