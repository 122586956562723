import * as React from 'react';
import Peer from 'simple-peer';
import { Video } from './Video';

export interface IRemoteVideosProps {
  peers: {[id: string]: Peer.Instance}
  localVideo: any
}

export interface IRemoteVideosState {
}

export default class RemoteVideos extends React.Component<IRemoteVideosProps, IRemoteVideosState> {
  constructor(props: IRemoteVideosProps) {
    super(props);

    this.state = {
    }
  }

  getWidth(length: number): React.CSSProperties {
    if (length > 10) return {width: "25%", height: "25%"};
    if (length > 3) return {width: "33,3333%", height: "33,3333%"};
    if (length > 1) return {width: "50%"};
    return {width: "100%"};
  }

  public render() {
    const { peers, localVideo } = this.props;
    let length = Object.values(peers).length;
    let style = this.getWidth(length)
    // let localStyle: React.CSSProperties = length === 1 ? {position: "absolute", width: "30%", bottom: "0", right: "0"} : style;
    return (
      <>
        {Object.keys(peers).map((id, index) => <Video style={style} key={id} peer={peers[id]} socketId={id} />)}
        <video ref={localVideo} autoPlay playsInline muted id="local-video" className="local-video"></video>
      </>
    );
  }
}
