import * as React from 'react';
import { visioBloc } from '../../bloc/VisioBloc';
import Visio from '../../models/Visio';
import TextIcon from '../common/TextIcon';

export interface IVideoCallViewProps {
  visio: Visio
}

export interface IVideoCallViewState {
}

export default class VideoCallView extends React.Component<IVideoCallViewProps, IVideoCallViewState> {

  public render() {
    const {visio} = this.props;
    if (visio.status === "pending") return (
      <div className="bg-light p-4 rounded-2">
        <div className="row-center mb-3">
          {/* <AvatarLetter name={room.name} /> */}
          <div className="ms-3 text-l">
            <TextIcon spacing={2} leftIcon="fas fa-video">Visio demandé</TextIcon>
          </div>
        </div>
        <div className="row-between mx-n2">
          <button onClick={() => visioBloc.rejectCall(visio)} className="btn btn-danger flex-1 mx-2">Annuler</button>
          <button onClick={() => visioBloc.acceptCall(visio)} className="btn btn-primary flex-1 mx-2">Accepter</button>
        </div>
      </div>
    );
    // if (visio.status === "running") return (
    //   <div className="bg-light p-4 rounded-2">
    //     <div className="row-center mb-3">
    //       {/* <AvatarLetter name={room.name} /> */}
    //       <div className="ms-3 text-l">
    //         <TextIcon spacing={2} leftIcon="fas fa-video">Visio en cours</TextIcon>
    //       </div>
    //     </div>
    //     <div className="row-between mx-n2">
    //       <button onClick={() => visioBloc.acceptCall(visio)} className="btn btn-primary flex-1 mx-2">Rejoindre</button>
    //     </div>
    //   </div>
    // );
    console.log(visio);
    return <></>
    
  }
}
