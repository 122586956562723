import * as React from 'react';
import { consumeDisplayBloc, IDisplayBlocConsumer } from '../bloc/DisplayBloc';
import MessagesArea from '../components/messaging/MessagesArea';
import RoomsArea from '../components/messaging/RoomsArea';
import VisioArea from '../components/messaging/VisioArea';
import SettingsService from '../services/SettingsService';
import DisplayBloc from '../bloc/DisplayBloc';

export interface IHomeProps {
}

function Home (props: IDisplayBlocConsumer) {

  if (!SettingsService.loaded) return <></>
  const transition = "300ms"
  return (
    <>
      <div style={{flex: DisplayBloc.styles[props.mode].roomAreaWidth, transition, minWidth: "360px"}}>
        <RoomsArea/>
      </div>
      <div style={{flex: DisplayBloc.styles[props.mode].messagesAreaWidth, transition, minWidth: "360px"}}>
        <MessagesArea/>
      </div>
      <div style={{flex: DisplayBloc.styles[props.mode].visioAreaWidth, transition, minWidth: "360px"}}>
        <VisioArea/>
      </div>
    </>
  );
}

export default consumeDisplayBloc(Home);