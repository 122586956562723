import Attribute from "./core/Attribute";
import AttributesCollection from "./core/AttributeCollection";
import Message from './Message';
import Model from "./Model";
import ArrayModelAttribute from './core/ArrayModelAttribute';
import DateAttribute from './core/DateAttribute';
import ModelAttribute from './core/ModelAttribute';
import User from './User';
import dayjs from 'dayjs';
import AppBloc from '../bloc/AppBloc';
import SocketEntityManager from "../services/SocketEntityManager";
import Visio from "./Visio";

export default class Room extends Model {

  public ip: string;
  public device: string;
  public name: string;
  public email: string;
  public phone: string;
  public adminReadAt: Date;
  public clientReadAt: Date;
  public userId: number;
  public messages: Message[];
  public messageCount: number
  public visios: Visio[]

  static modelUrl: string = "rooms";
  public modelName: string = 'room';

  public attributes = new AttributesCollection({
    ip: new Attribute("ip"),
    device:  new Attribute("device"),
    name:  new Attribute("name"),
    email:  new Attribute("email"),
    phone:  new Attribute("phone"),
    messageCount:  new Attribute("messageCount"),
    adminReadAt:  new DateAttribute("adminReadAt"),
    clientReadAt:  new DateAttribute("clientReadAt"),
    user:  new ModelAttribute("user", User),
    messages:  new ArrayModelAttribute("messages", Message),
    visios:  new ArrayModelAttribute("visios", Visio),
  });

  constructor(json) {
    super(json)
    this.fillAttributes(json);
  }

  lastMessage() {
    return this.messages[0];
  }

  isRead() {
    return this.unreadAmount() === 0;
  }

  unreadAmount() {
    let amount = 0;
    for ( const message of this.messages) {
      if (!message.fromMe() && dayjs(message.createdAt).isAfter(this[AppBloc.get().readAtName])) amount++;
      else break;
    }
    return amount;
  }

  hasMoreMessages() {
    return this.messageCount > this.messages.length;
  }


  async read() {
    this.adminReadAt = new Date();
    await SocketEntityManager.update(this);
  }

}
