import classNames from 'classnames';
import * as React from 'react';
import SmartInput, { ISmartInputProps } from './SmartInput';


export interface ISmartSwitchProps extends ISmartInputProps {
  checked?: boolean;
  primary?: boolean;
  color?: string;
}

export default function SmartSwitch(props: ISmartSwitchProps) {

  const { color, primary } = props;
  return (
    <SmartInput
      {...props}
      render={({updateState, containerClassName, label, inputProps}) => (
        <div className={ classNames({ [containerClassName]: !!containerClassName, "smart-switch": true, "smart-switch-primary": primary })}>
          <input {...inputProps} checked={inputProps.checked || inputProps.value} type="checkbox" onChange={(e) => updateState(e.target.checked)}/>
          <label style={{background: inputProps.checked ? color : null}} htmlFor={inputProps.id} className="check-trail">
            <span className="check-handler"></span>
          </label>
          <label htmlFor={inputProps.id} className="switch-label mb-0">
            {label}
          </label>
        </div>
      )}
    />
  );
}
