import { sessionBloc } from '../bloc/SessionBloc';
import Attribute from './core/Attribute';
import AttributesCollection from './core/AttributeCollection';
import Model from './Model';

export default class User extends Model {

  public firstname: string
  public lastname: string
  public email: string
  public phone: string
  public type: string
  public password: string;
  
  static modelUrl = "users";
  public modelName = "user";

  public attributes = new AttributesCollection({
    firstname: new Attribute("firstname"),
    lastname: new Attribute("lastname"),
    email: new Attribute("email"),
    phone: new Attribute("phone"),
    type: new Attribute("type"),
    password: new Attribute("password"),
  });

  constructor(json) {
    super(json)
    this.fillAttributes(json);
  }

  public getFullName(): string {
    let str = [this.firstname, this.lastname].filter(s => s);
    return str.join(" ");
  }

  public isMe = (): boolean => sessionBloc.state.user.id === this.id;


}