
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import AdminRouter from '../../AdminRouter';
import { consumeSessionBloc, ISessionBlocConsumer } from '../../bloc/SessionBloc';


export interface INavbarProps {
  transparent?: boolean
  shadow?: boolean
}

export interface INavbarState {
}

function Navbar(props: ISessionBlocConsumer) {

  return (<>
    <nav className="navbar">
      <div className='pt-5'>
        <img height={"50px"} src="https://allocopieurs.com/wp-content/uploads/2021/12/cropped-cropped-p4ytr-180x180.png" alt="" />
      </div>
      <div className="w-100">
        {AdminRouter.map(route => (
          <NavLink className="mb-3" key={route.url} to={route.url} exact={route.exact}>
            <div className="sidebar-link height-8 rounded row-center width-8">
              <i className={"fas " + route.icon} ></i>
              {/* <TextIcon leftIcon={route.icon} >{route.label}</TextIcon> */}
            </div>
          </NavLink>
        ))}
      </div>
      <div style={{height : "100px"}}>
        
      </div>
    </nav>
    </>
  );
}

export default consumeSessionBloc(React.memo(Navbar, (prevProps, nextProps) => prevProps.logged === nextProps.logged));
