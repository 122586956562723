import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import SessionBloc from './bloc/SessionBloc';
import reportWebVitals from './reportWebVitals';
import 'dayjs/locale/fr';
import dayjs from "dayjs";
import * as Sentry from "@sentry/react";
import { Integrations } from '@sentry/tracing';
dayjs.locale('fr')

Sentry.init({
  dsn: "https://bfb5016c654a4843ae56129ef9e6478e@o709571.ingest.sentry.io/5778820",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
ReactDOM.render(
  <Sentry.ErrorBoundary >
  <BrowserRouter>
    <SessionBloc>
      <App />
    </SessionBloc>
  </BrowserRouter>
  </Sentry.ErrorBoundary>,
  document.getElementById('urioz-visio-app')
);

reportWebVitals();
